import "@/assets/less/template.less";

/*******************************************
* GLOBAL VARIABLES
*******************************************/
var w;
var $ = window.jQuery;

/**
 * Main Menu Functionality
 */
var $mainMenu = $('nav.menu.main-menu');

console.log(getWindowWidth());
// Toggle active state on #navwrap element
if( getWindowWidth() > 959 ) {
	$('.menu-item-level-1.parent').on('mouseover', function(e) {
			$('#navwrap').addClass('sub-menu-active');
	})

	$('.menu-item-level-1.parent').on('mouseout', function(e) {
			$('#navwrap').removeClass('sub-menu-active');
	})
}

// Mobile menu interactivity

$('.view-sub-menu').on('touchstart', function(e) {
	// Add class to the .sub-menu-outer-container
	$(this).next('.sub-menu-outer-container').addClass('active');
	// Add class to menu to indicate sub-menu is active
	$('.menu.main-menu').addClass('sub-menu-active');
})

$('.back-to-menu').on('click', function(e) {
	$(this).closest('.sub-menu-outer-container.active').removeClass('active');
	// Remove class on menu to indicate sub-menu is active
	$('.menu.main-menu').removeClass('sub-menu-active');
})

$('.open-menu').on('click', function(e) {
	e.preventDefault();
	$('.menu.main-menu').addClass('active');
})

$('.close-menu').on('click', function(e) {
	e.preventDefault();
	$('.menu.main-menu').removeClass('active');
})

// Add a class to the search container to make it full width on mobile
// Not required on desktop
$('.top-search .search-input').on('focusin', function() {
	$(this).closest('.top-search').addClass('active');
})
$('.top-search .search-input').on('focusout', function() {
	$(this).closest('.top-search').removeClass('active');
})

/**
 * Google Map Initiation
 * 
 */

	if (jQuery('body.find-your-local-depot').length > 0) {
function initMap() {

  var mapOptions = {
    zoom: 6,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    center: new google.maps.LatLng(54.236107,-4.548056)
  };

	var locations = [
	['Direct Seafoods Colchester', 51.921038, 0.929105, '1 Crown Court', 'Clough Road', 'Severalls Industrial Estate', 'Colchester </br> Essex </br>CO4 9TZ', '01206 752 075', 'sales@directseafoodscolchester.co.uk', 'http://www.directseafoods.co.uk/our-businesses/east-south-east/direct-seafoods-colchester/'],
	['Kingfisher Midlands', 52.527676, -1.925107, 'Unit E', 'Austin Way', 'Great Barr', 'Birmingham </br> B42 1DU', '0121 622 8830', 'sales@kingfishermidlands.co.uk', 'http://www.directseafoods.co.uk/our-businesses/midlands/kingfisher-midlands/'],
	['Neve Fleetwood', 53.919265, -3.017250, '19 Copse Road', 'Fleetwood', 'Lancashire', 'FY7 6RP', '01253 774 100', 'sales@nevefleetwood.co.uk', 'http://www.directseafoods.co.uk/our-businesses/north-west/neve-fleetwood/'],
	['Taylor Foods', 55.019843, -1.491200, '9a Elm Rd', 'West Chirton North Industrial Estate', 'North Shields', 'NE29 8SE', '0191 258 2957', 'sales@taylorfoods.co.uk', 'http://www.directseafoods.co.uk/our-businesses/north-east/taylor-foods/'],
	['Campbell Brothers Fish', 55.873872, -3.097034, 'Sherwood Industrial Estate', 'Bonnyrigg', 'Midlothian', 'EH19 3LW', '01506 856 832', 'sales@campbellbrothersfish.co.uk', 'http://www.directseafoods.co.uk/our-businesses/scotland/campbell-brothers-fish/'],
	['Channel Fisheries', 50.3808507, -4.0185549, 'Torbay Business Park', 'Unit 4/5', 'Woodview Rd', 'Paignton </br>TQ4 7HP', '01803 858 126', 'sales@channelfisheries.com', 'http://www.directseafoods.co.uk/our-businesses/west-south-west/channel-fisheries/'],
	['Southbank Fresh Fish', 51.3679644, -0.6870779, 'Bermondsey Trading Estate', 'Rotherhithe New Road ', 'London', 'SE16 3LL', '0207 639 6000', 'sales@southbankfreshfish.co.uk', 'http://www.directseafoods.co.uk/our-businesses/london/southbank-fresh-fish/'],
	['Murrays Fresh Fish', 51.2091036, -0.2687899, 'Bermondsey Trading Estate', 'Rotherhithe New Road ', 'London', 'SE16 3LL ', '0208 963 0110', 'sales@murraysfreshfish.com', 'http://www.directseafoods.co.uk/our-businesses/london/murrays-fresh-fish/'],
	['Direct Seafoods London', 51.2985374, 0.1452253, 'Unit 14-15 ', 'Bermondsey Trading Estate', 'Rotherhithe New Road', 'London </br> SE16 3LL ', '0207 358 1617', 'sales@directseafoodslondon.co.uk', 'http://www.directseafoods.co.uk/our-businesses/london/direct-seafoods-london/'],
	['Kingfisher Brixham', 50.413475, -3.587080, 'Torbay Business Park', 'Unit 4/5', 'Woodview Rd', 'Paignton </br> TQ4 7HP', '01803 553 232', 'sales@kingfisherbrixham.co.uk', 'http://www.directseafoods.co.uk/our-businesses/west-south-west/kingfisher-brixham/'],
	['Daily Fish Supplies', 51.6601762, -0.1928247, 'Unit 10-14', 'Cedar Way Industrial Estate', 'Camley Street', 'London </br> N1C 4PD', '0207 383 3771', 'sales@dailyfishsupplies.co.uk', 'http://www.directseafoods.co.uk/our-businesses/london/daily-fish-supplies/']
	];
  var map = new google.maps.Map(document.getElementById('map'), mapOptions);

  var infowindow = new google.maps.InfoWindow();

  jQuery.each(locations, function(i, item) {

		var name = locations[i][0]
		var lat = locations[i][1]
		var long = locations[i][2]
		var add1 =  locations[i][3]
		var add2 =  locations[i][4]
		var add3 =  locations[i][5]
		var add4 =  locations[i][6]
		var phone =  locations[i][7]
		var email =  locations[i][8]
		var depot =  locations[i][9]

		latlngset = new google.maps.LatLng(lat, long);

		 var marker = new google.maps.Marker({
					 map: map, title: name , position: latlngset, icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
		 });

     var content = '<p class="address-details"> <strong>' + name +  '</strong> <span> ' + add1 + '</span> <span>' + add3 + '</span> <span>' + add4 + '</span> </p> <div class="contact-details"><span class="phone-number"><strong>Phone</strong> <a href="tel:+44' + phone + ' " title="Phone">'+ phone +'</a> </span> <span class="email-address"><strong>Email </strong> <a href="mailto:' + email + ' " title="Email">'+ email+' </a> </span> <span><a href=" '+ depot + '   " title="View Depot" class="btn grad"> View Depot</a></span>'

    // var marker = new google.maps.Marker({
    //   position: item[0],
    //   map: map,
    //   title: item[1],
    // });

    google.maps.event.addListener(marker, 'click', (function(marker) {

      return function() {
        infowindow.setContent(content);
        infowindow.open(map, marker);
      }

    })(marker));
  });


}

google.maps.event.addDomListener(window, 'load', initMap);






// function initMap() {
// 	// var uk = {lat: , lng: -3.435973 };
// 	// var map = new google.maps.Map(document.getElementById('map'), {
// 	// 	zoom: 6,
// 	// 	center: uk
// 	// });
//
// 	var myOptions = {
// 		center: new google.maps.LatLng(55.378051,-3.435973 ),
// 		zoom: 6,
// 		mapTypeId: google.maps.MapTypeId.ROADMAP
//
// 	};
// 	var map = new google.maps.Map(document.getElementById("map"),
// 			myOptions);
//
// 	setMarkers(map,locations)
// }




  //
  // function setMarkers(map,locations){
  //
  // var marker, i
  //
	// for (i = 0; i < locations.length; i++) {
  //
	// 	 var name = locations[i][0]
	// 	 var lat = locations[i][1]
	// 	 var long = locations[i][2]
	// 	 var add1 =  locations[i][3]
	// 	 var add2 =  locations[i][4]
	// 	 var add3 =  locations[i][5]
	// 	 var add4 =  locations[i][6]
	// 	 var phone =  locations[i][7]
	// 	 var email =  locations[i][8]
	// 	 var depot =  locations[i][9]
  //
	// 	 latlngset = new google.maps.LatLng(lat, long);
  //
	//   	var marker = new google.maps.Marker({
	//           map: map, title: name , position: latlngset
	//     });
  //
	// 		map.setCenter(marker.getPosition())
  //
  //
  //     var content = '<p class="address-details"> <strong>' + name +  '</strong> <span> ' + add1 + '</span> <span>' + add3 + '</span> <span>' + add4 + '</span> </p> <div class="contact-details"><span class="phone-number"><strong>Phone</strong> <a href="tel:+44' + phone + ' " title="Phone">'+ phone +'</a> </span> <span class="email-address"><strong>Email </strong> <a href="mailto:' + email + ' " title="Email">'+ email+' </a> </span> <span><a href=" '+ depot + '   " title="View Depot" class="btn grad"> View Depot</a></span>'
	// 			// create info window
	// 			var infoWindow = new google.maps.InfoWindow({
	// 				content		: content
	// 			});
  //
	//   	// var infowindow = new google.maps.InfoWindow({
	// 		// })
  //
	// 			infoWindows.push(infoWindow);
  //
	//   }
  //
	// 	// show info window when marker is clicked
	// google.maps.event.addListener(marker, 'click', function() {
  //
	// 			//close all
	// 			for (var i = 0; i < infoWindows.length; i++) {
	// 				infoWindows[i].close();
	// 			}
  //
	// 			infoWindow.open( map, marker );
	// 		});
  //
	// 		google.maps.event.addListener(map, 'click', function() {
	// 			infoWindow.close();
	// 		});
  // }
}


// RE-POSITION FISHMONGERS (HOME-GRID) INTRO 900PX & ABOVE
jQuery(window).resize(function(){




	w = getWindowWidth();
	searchMove();
	contactLink();

	if (jQuery(window).width() > 900) {
		jQuery('#gridwrap1 div.half.fishmongers .content-container').insertAfter(jQuery('#gridwrap1 div.half.fishmongers .image-container'));
	}
	else {
		jQuery('#gridwrap1 div.half.fishmongers .content-container').insertBefore(jQuery('#gridwrap1 div.half.fishmongers .image-container'));
	}
});

// function getWindowWidth(){
// 	return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
// }

/**
 * Not sure this is required
 */
function searchMove() {
	if (w < 730) {
		jQuery('#topwrap div.search').insertAfter(jQuery('#topwrap div.menu-reveal'));
	}
	else {
		jQuery('#topwrap div.search').insertBefore(jQuery('#topwrap div.logo'));
	}
}
/**
 * Not sure this is required
 */
function contactLink() {
	if (w < 730) {
		jQuery('#navwrap ul.nav.menu li.contact-link').insertAfter(jQuery('#navwrap ul.nav.menu li.journal-link'));
	}
	else {
		jQuery('#navwrap ul.nav.menu li.contact-link').insertBefore(jQuery('#navwrap ul.nav.menu li.our-business'));
	}
}

/* USER LOGIN FOR ONLINE ORDERING FUNCTIONS - START */

function newLogin () {
	var accessToken, refreshToken;
	var ssoTenant = "prod_freshuk";

	jQuery("#ssoUrl").on("click", function(e) {
			var userId = jQuery('#userId').val();
			var password = jQuery('#password').val();
			var data = {
				grant_type: 'password',
				username: userId,
				client_id: 'BDirectShop',
				scope: 'offline_access openid api',
				acr_values: 'tenant:'+ ssoTenant,
				password: password
			};
			jQuery.support.cors = true;
			jQuery.ajax({
					type: "POST",
					url: "https://identity.freshfoodhub.co.uk/core/connect/token",
					data: data,
					success: function(response) {
						console.log('It Works');
							jQuery('input[name=sessionToken]').val(response.access_token);
							jQuery('input[name=refreshToken]').val(response.refresh_token);
							jQuery("#ssoForm").submit();
					},
					error: function(response){
						alert("There was a problem logging into the ordering system. Please try clearing your browser cache. You’ll be redirected to the online ordering login page.");
						setTimeout("location.href = 'https://www.freshfoodhub.co.uk';",1000);
					}
			});
	});
}

function setCookie(cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays*24*60*60*1000));
	var expires = 'expires=' + d.toUTCString();
	document.cookie = cname + '=' + cvalue + '; ' +expires;
}

function getCookie(cname) {
	var name = cname + '=';
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') c = c.substring(1);
		if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
	}
	return '';
}

function checkUserData() {
	var form = jQuery('#hubLogin');
	var user = getCookie('username');
	var pass = getCookie('password');
	if (user != '') {
		form.find('input[name="LoginID"]').val(user);
	}
	if (pass != '') {
		form.find('input[name="Password"]').val(pass);
	}
}

/* USER LOGIN FOR ONLINE ORDERING FUNCTIONS - END */


jQuery(document).ready(function() {

if (jQuery('body.contact').length > 0) {
	document.addEventListener( 'wpcf7mailsent', function( event ) {
    location = 'http://www.directseafoods.co.uk/thank-you';
		}, false );
};

	w = getWindowWidth();
	searchMove();

	// Or use this to Open link in same window (similar to target=_blank)
	jQuery(".tile").click(function(){
		window.location = jQuery(this).find("a:first").attr("href");
		return false;
	});
  //
	jQuery("#postcodeInput").keyup(function(event){
    if(event.keyCode == 13){
        jQuery("#searchButton").click();
    }
	});


		jQuery("#postInput").keyup(function(event){
	    if(event.keyCode == 13){
	        jQuery("#postSearch").click();
	    }
		});


	if (jQuery(window).width() > 900) {
		jQuery('#gridwrap1 div.half.fishmongers .content-container').insertAfter(jQuery('#gridwrap1 div.half.fishmongers .image-container'));
	}
	else {
		jQuery('#gridwrap1 div.half.fishmongers .content-container').insertBefore(jQuery('#gridwrap1 div.half.fishmongers .image-container'));
	}
	/*******************************************
	 * HELPER FUNCTIONS *
	 *******************************************/

	/*
	 * Image lazy loading
	 * Further reading: https://www.appelsiini.net/projects/lazyload
	 * Example image tag:  <img class="lazy" data-original="img/example.jpg" width="640" height="480">
	 * Uncomment out the code below and in the index.php file to enable lazyloading
	 */

	// jQuery("img.lazy").lazyload({
	//     threshold : 200
	// });

	/*
	 * Check alt tags aren't empty for images
	 * For development use only
	 */

	// jQuery('img').each(function() {
	// 	console.log(jQuery(this).attr('alt'));
	// });

	/*******************************************
	 * RESPONSIVE NAV *
	 *******************************************/

	jQuery('.nav-btn').on('click', function() {
		jQuery('html').addClass('js-nav');
	});

	jQuery('.close-btn, .js-nav #innerwrap').on('click', function() {
		jQuery('html').removeClass('js-nav');
	});

	jQuery('.depot-btn').on('click', function() {
		jQuery('html').addClass('js-depot');
	});

	jQuery('.close-action .close-btn, .js-depot #topwrap .depot-search').on('click', function() {
		jQuery('html').removeClass('js-depot');
	});

	jQuery('.menu-reveal .menu-btn').on('click', function() {
		jQuery('html').addClass('js-nav');
	});

	jQuery('.close-btn, .js-nav #navwrap').on('click', function() {
		jQuery('html').removeClass('js-nav');
	});

	/* Mobile Search */
	jQuery('span.mobile-search').click(function(e) {
		e.preventDefault();
		jQuery('div.search').fadeToggle();
	});
	/* End Of Mobile Search */


	/* ONLINE ORDERING LOGIN  */
	newLogin();
	jQuery('.ffh-login .login-cta').click(function(e){
		e.preventDefault();
		jQuery('#ffh-login-container').fadeToggle();
		// check for cookies
		checkUserData();
	});

	jQuery('#ffh-login-close').click(function(e) {
		e.preventDefault();
		jQuery('#ffh-login-container').fadeToggle();
	})
	/* ONLINE ORDERING LOGIN - END  */


	// jQuery('#topwrap div.depot-search').insertBefore(jQuery('#topwrap > .internal-container'));

	jQuery('#navwrap #menu .follow-us').insertAfter(jQuery('#navwrap #menu ul li#menu-item-71'));
	jQuery('#navwrap #menu .contact-details').insertAfter(jQuery('#navwrap #menu ul li#menu-item-75 a'));

	jQuery('#banner .hero-banner.internal .banner-container h1').prependTo('#banner .hero-banner.internal .banner-container .banner-text').addClass('title');
	jQuery('#banner .hero-banner.internal .banner-container #breadcrumbs').insertBefore(jQuery('#banner .hero-banner.internal .banner-container .banner-text h1'));
	jQuery('#banner .hero-banner .banner-text a.cta').on('click', function() {
		var scrollToElem = jQuery(this).attr('href');
		jQuery('body, html').animate({
			scrollTop: (jQuery(scrollToElem).offset().top)
		}, 1000);
	});

	jQuery('#wpsl-gmap .wpsl-info-window .wpsl-info-actions').insertBefore(jQuery('#wpsl-gmap .wpsl-info-window p'));
	jQuery('body.find-your-local-depot main .depot-locator > .depot-description').insertBefore(jQuery('body.find-your-local-depot main .depot-locator > div#wpsl-result-list'));

	jQuery(".jDate").text( (new Date).getFullYear() );

	/* --- HOMEPAGE BUBBLES - START --- */

	/* Bubbles Created - START */
	jQuery('#gridwrap1').append('<span class="bubble one"></span>');
	jQuery('#gridwrap1').append('<span class="bubble two"></span>');
	jQuery('#gridwrap1').append('<span class="bubble three"></span>');
	jQuery('#gridwrap1').append('<span class="bubble four"></span>');
	jQuery('#gridwrap1').append('<span class="bubble five"></span>');
	jQuery('#gridwrap1').append('<span class="bubble six"></span>');
	jQuery('#gridwrap1').append('<span class="bubble seven"></span>');
	jQuery('#gridwrap1').append('<span class="bubble eight"></span>');
	jQuery('#gridwrap1').append('<span class="bubble nine"></span>');
	jQuery('#gridwrap1').append('<span class="bubble ten"></span>');
	/* Bubbles Created - END */

	/* Bubble Animation - START */
	jQuery('body.home #gridwrap1 .journey').hover(
		function(){
        	jQuery('span.bubble.one').animate({
				'marginTop' : "30",
			}, 3500);
			jQuery('span.bubble.two').animate({
				'marginLeft' : "-40",
			}, 2500);
			jQuery('span.bubble.three').animate({
				'marginTop' : "60",
			}, 3500);
			jQuery('span.bubble.four').animate({
				'marginLeft' : "70",
			}, 2000);
		}, function(){
			jQuery('span.bubble.one').animate({
				'marginTop' : "0",
			}, 3500);
			jQuery('span.bubble.two').animate({
				'marginLeft' : "0",
			}, 2500);
			jQuery('span.bubble.three').animate({
				'marginTop' : "0",
			}, 3500);
			jQuery('span.bubble.four').animate({
				'marginLeft' : "0",
			}, 2000);

	});

	jQuery('body.home #gridwrap1 .sustainable').hover(
		function(){
			jQuery('span.bubble.five').animate({
				'marginTop' : "30",
				'marginLeft' : "-30",
			}, 3500);
			jQuery('span.bubble.six').animate({
				'marginTop' : "10",
				'marginRight' : "50",
			}, 2000);
			jQuery('span.bubble.seven').animate({
				'marginTop' : "10",
				'marginRight' : "20",
			}, 3500);
		}, function(){
			jQuery('span.bubble.five').animate({
				'marginTop' : "0",
				'marginLeft' : "0",
			}, 3500);
			jQuery('span.bubble.six').animate({
				'marginTop' : "0",
				'marginRight' : "0",
			}, 2000);
			jQuery('span.bubble.seven').animate({
				'marginTop' : "0",
				'marginRight' : "0",
			}, 3500);
	});

	jQuery('body.home #gridwrap1 .fishmongers').hover(
		function(){
			jQuery('span.bubble.eight').animate({
				'marginTop' : "30",
				'marginLeft' : "70",
			}, 3000);
			jQuery('span.bubble.nine').animate({
				'marginBottom' : "5",
				'marginLeft' : "25",
			}, 2500);
			jQuery('span.bubble.ten').animate({
				'marginBottom' : "15",
				'marginLeft' : "-5",
			}, 3500);
		}, function(){
			jQuery('span.bubble.eight').animate({
				'marginTop' : "0",
				'marginLeft' : "0",
			}, 3000);
			jQuery('span.bubble.nine').animate({
				'marginBottom' : "0",
				'marginLeft' : "0",
			}, 2500);
			jQuery('span.bubble.ten').animate({
				'marginBottom' : "0",
				'marginLeft' : "0",
			}, 3500);
	});
	/* Bubble Animation  - END */

	/* --- HOMEPAGE BUBBLES - END --- */

	/*******************************************
	 * Google Map *
	 *******************************************/

	if (jQuery('body.find-your-local-depot').length > 0) {
	   initMap()
	}


	 /*******************************************
 	 * End Of Google Map *
 	 *******************************************/




	/* OWL-CAROUSEL FOR HOMEPAGE BLOG ITEMS */
	jQuery('.testimonials-slider.owl-carousel').owlCarousel({
		// center: true,
		autoplay: true,
		autoplayTimeout: 5000,
		autoplayHoverPause: true,
		dots: false,
		items: 1,
		loop: true,
		mouseDrag: false,  
    	nav: false,
	});

	jQuery('.category-posts-internal.owl-carousel').owlCarousel({
		// center: true,
		autoplay: true,
		autoplayTimeout: 5000,
		autoplayHoverPause: false,
    	margin: 25,
    	nav: false,
		loop: true,
		dots: true,
		mouseDrag: false,  
		responsive:{
			0: {
				items: 1
			},
			500: {
				items: 2
			}
		}
	});

	jQuery('#related-fish-bottom').owlCarousel({
		// center: true,
		autoplay: false,
		autoplayTimeout: 5000,
		autoplayHoverPause: false,
			margin: 25,
			nav: false,
		loop: false,
		dots: true,
		mouseDrag: true,
		responsive:{
			0: {
				items: 1
			},
			500: {
				items: 2
			},
			900: {
				items: 3
			}
		}
	});

	/* OWL-CAROUSEL FOOTER PARTNER LOGOS */
	jQuery('ul#partners-logo').owlCarousel({
		loop: true,
		autoplay: true,
		autoplayTimeout: 4000,
		autoplayHoverPause: false,
		margin: 25,
    	nav: false,
		dots: false,
		responsive:{
			0: {
				items: 2
			},
			500: {
				items: 3
			},
			730: {
				items: 4
			},
			1000: {
				items: 5
			},
			14000: {
				items: 6
			}
		}
	});


	/* OWL-CAROUSEL FOR INTERNAL FISH RECIPES */
	jQuery('.recipes-container #recipes').owlCarousel({
		loop:false,
		autoplay:true,
  	margin:0,
  	nav:true,
		mouseDrag: true,
		dots:true,
		responsive:{
			0:{
				items:1
			},
			750:{
				items:2
			},
			1000: {
				items:3
			}
		}
	});
});





// When the window is resized
jQuery(window).resize(function() {
	w = getWindowWidth();
	if (jQuery('body.find-your-local-depot').length > 0) {
	   initMap()
	}
}).resize();

function getWindowWidth() {
	return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}
